/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAy5qlBKeYlg2J7voy-MZtRDyhkbJbkKhk",
  "appId": "1:591393697104:web:be8a1dbb36edb8c2d9d055",
  "authDomain": "schooldog-i-taylor-ga.firebaseapp.com",
  "measurementId": "G-12GTPJ9SFY",
  "messagingSenderId": "591393697104",
  "project": "schooldog-i-taylor-ga",
  "projectId": "schooldog-i-taylor-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-taylor-ga.appspot.com"
}
